import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import * as styles from './SkewSection.module.scss';

const SkewSection = ({ background, location, title, children }) => {
  return (
    <div className={classNames(styles.wrapper, styles[background])}>
      {children}
    </div>
  );
};

export default SkewSection;
