import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import Slider from 'react-slick';

import Title from '../Title/Title';

import * as styles from './SponsorList.module.scss';

type SponsorProp = {
  name: string;
  body: string;
  logo: any;
  link?: string;
};

type SponsorListProps = {
  isSlider: boolean;
  sponsors: Array<SponsorProp>;
  title: string;
};

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const SponsorList = ({ isSlider, sponsors, title }: SponsorListProps) => {
  const sliderSettings = {
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
  };

  const getImage = (sponsor: SponsorProp) => (
    <GatsbyImage
      alt={sponsor.name}
      className={styles.image}
      image={sponsor.logo}
      loading="lazy"
    />
  );

  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <Title text={title} />

        <div className={classNames(!isSlider ? styles.list : styles.slider)}>
          <ConditionalWrapper
            condition={isSlider}
            wrapper={(children) => (
              <Slider {...sliderSettings}>{children}</Slider>
            )}
          >
            {sponsors.map((sponsor) => {
              return (
                <div className={styles.sponsor} key={sponsor.name}>
                  {sponsor.link ? (
                    <a
                      href={sponsor.link}
                      rel="nofollow noopener"
                      target="_blank"
                    >
                      {getImage(sponsor)}
                    </a>
                  ) : (
                    getImage(sponsor)
                  )}
                  {sponsor.body ? (
                    <p className={styles.body}>{sponsor.body}</p>
                  ) : null}
                </div>
              );
            })}
          </ConditionalWrapper>
        </div>
      </div>
    </section>
  );
};

export default SponsorList;
