import * as React from 'react';
import classNames from 'classnames';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import Button from '../Button/Button';

import * as styles from './ShoppingInfo.module.scss';

const ShoppingInfo = ({ body, button, image, order, title, url }) => (
  <section className={styles.wrapper}>
    <div className={styles.container}>
      <h3 className={styles.name}>
        <span className={classNames(styles.order, styles[`order${order}`])}>
          {order}
        </span>{' '}
        {title}
      </h3>
      <div className={styles.imageContainer}>
        {image ? (
          <GatsbyImage
            alt={title}
            className={styles.image}
            image={getImage(image)}
          />
        ) : (
          <StaticImage
            className={styles.image}
            layout="constrained"
            formats={['AUTO', 'WEBP', 'AVIF']}
            src="./../../images/easy-fundraising.jpg"
            width={500}
            quality={95}
            alt={title}
          />
        )}
      </div>
      {body && (
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: body }}
        ></p>
      )}
      {button && <Button text={button} url={url} />}
    </div>
  </section>
);

export default ShoppingInfo;
