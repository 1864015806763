import * as React from 'react';
import { Link } from 'gatsby';

import Title from '../Title/Title';
import ShoppingInfo from '../ShoppingInfo/ShoppingInfo';

import * as styles from './Shopping.module.scss';

const Shopping = ({ shopping }) => (
  <section className={styles.wrapper}>
    <div className={styles.container}>
      <Title text="Shop with us" />

      <div className={styles.layout}>
        {shopping.map((shop) => (
          <ShoppingInfo key={shop.title} {...shop} />
        ))}
      </div>
    </div>
  </section>
);

export default Shopping;
