import * as React from 'react';
import { Link } from 'gatsby';

import { getImage } from 'gatsby-plugin-image';
import SponsorList from '../../components/SponsorList/SponsorList';

type SponsorProp = {
  name: string;
  logo: any;
  link?: string;
};

type DataProps = {
  isSlider: boolean;
  sponsors: Array<SponsorProp>;
  title: string;
};

const SponsorContainer: React.FC<DataProps> = ({
  isSlider,
  sponsors,
  title,
}) => {
  if (!sponsors || !Array.isArray(sponsors)) {
    return null;
  }

  const sponsorList = sponsors.map((sponsor: SponsorProp) => ({
    ...sponsor,
    logo: sponsor.logo ? getImage(sponsor.logo) : null,
  }));

  return (
    <SponsorList isSlider={isSlider} sponsors={sponsorList} title={title} />
  );
};

export default SponsorContainer;
