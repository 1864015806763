import * as React from 'react';
import { graphql } from 'gatsby';

import PageLayout from '../components/PageLayout/PageLayout';
import SEO from '../components/seo';
import BlogContainer from '../containers/BlogContainer/BlogContainer';
import DonateSummary from '../components/DonateSummary/DonateSummary';
import Shopping from '../components/Shopping/Shopping';
import SkewSection from '../components/SkewSection/SkewSection';
import SponsorContainer from '../containers/SponsorContainer/SponsorContainer';
import NextEventList from '../components/NextEventList/NextEventList';

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMarkdownRemark.nodes;

  const news = posts.filter((post) => post.frontmatter.section === 'news');
  const { donateSettings, eventList, siteSettings, shopping, sponsors } = data;

  return (
    <PageLayout {...siteSettings} location={location}>
      <SEO title="Home" />
      {eventList.events && eventList.events.length > 0 ? (
        <NextEventList title={eventList.text} list={eventList.events} />
      ) : null}

      <BlogContainer
        hasSidebar={false}
        posts={news || []}
        moreLink="/news"
        title="Latest News"
      />
      <Shopping {...shopping} />
      <SkewSection background="even">
        <DonateSummary {...donateSettings} />
      </SkewSection>
      {eventList.events &&
        eventList.events.length > 0 &&
        eventList.events.map((event) => {
          return event && event.visible && event.sponsorVisible ? (
            <SponsorContainer
              isSlider={event.isSlider}
              key={event.title}
              sponsors={event.sponsors}
              title={event.title}
            />
          ) : null;
        })}
      <SponsorContainer {...sponsors} title="Our Sponsors" />
    </PageLayout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { section: { eq: "news" } } }
      limit: 3
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 500, formats: [AUTO, WEBP, AVIF])
            }
          }
          description
          section
        }
      }
    }
    shopping: shoppingYaml {
      shopping {
        title
        image {
          childImageSharp {
            gatsbyImageData(width: 500, formats: [AUTO, WEBP, AVIF])
          }
        }
        body
        button
        url
        order
      }
    }
    donateSettings: settingsYaml(section: { eq: "donate" }) {
      current
      goal
      reason
    }
    eventList: eventYaml {
      text
      events {
        date
        sponsors {
          body
          name
          logo {
            childImageSharp {
              gatsbyImageData(width: 300, formats: [AUTO, WEBP, AVIF])
            }
          }
          link
        }
        isSlider
        sponsorVisible
        title
        visible
      }
    }
    sponsors: sponsorsYaml {
      isSlider
      sponsors {
        body
        name
        logo {
          childImageSharp {
            gatsbyImageData(width: 300, formats: [AUTO, WEBP, AVIF])
          }
        }
        link
      }
    }
  }
`;
