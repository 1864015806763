import * as React from 'react';

import NextEvent from '../NextEvent/NextEvent';

import * as styles from './NextEventList.module.scss';

type NextEventListProps = {
  list: any;
  title?: string;
};

const NextEventList = ({ list, title = 'Next Event:' }: NextEventListProps) => {
  const eventCount = (list || []).filter((event) => event.visible).length;

  if (!eventCount) {
    return null;
  }

  return (
    <aside className={styles.wrapper}>
      <div className={styles.container}>
        <span className={styles.text}>{title}</span>
        {list.map((event) => {
          return event && event.visible ? (
            <NextEvent
              date={event.date}
              key={event.title}
              event={event.title}
            />
          ) : null;
        })}
      </div>
    </aside>
  );
};

export default NextEventList;
