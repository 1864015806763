import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import Title from '../Title/Title';

import * as styles from './DonateSummary.module.scss';
import Button from '../Button/Button';

type DonateProps = {
  current: string;
  goal: string;
  reason: string;
};

const DonateSummary = ({ current, goal, reason }: DonateProps) => {
  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <Title text="Donate" />

        <p className={styles.reason}>
          We are trying to raise <span>£{goal}</span>
          <br />
          for {reason}
        </p>

        <h2 className={styles.summary}>
          <span className={styles.prefix}>We've raised</span>{' '}
          <span className={styles.amount}>£{current}</span>
          <span className={styles.suffix}>so far!</span>
        </h2>

        <div className={styles.ctas}>
          <Button text="Donate" url="/donate" />
        </div>

        <p className={styles.thanks}>Thank you!</p>
      </div>
    </section>
  );
};

export default DonateSummary;
