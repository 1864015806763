import * as React from 'react';
import { Link } from 'gatsby';

import * as styles from './NextEvent.module.scss';

type NextEventProps = {
  event: string;
  date: Date;
  text?: string;
};

const NextEvent = ({ event, date, text = 'Next Event:' }: NextEventProps) => {
  const eventDate = new Date(date);

  const day = eventDate.toLocaleDateString('en-GB', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const time = eventDate.toLocaleTimeString('en-GB', {
    hour12: true,
    hour: 'numeric',
    minute: '2-digit',
  });

  return (
    <div className={styles.container}>
      <span className={styles.event}>{event}</span>
      {date ? (
        <span className={styles.date}>
          {day} {time !== '00:00 am' ? `@ ${time}` : null}
        </span>
      ) : null}
    </div>
  );
};

export default NextEvent;
